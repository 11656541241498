<template>
<section class="content mt-3">
			<div class="container-fluid">
				<div class="row card">
          <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
          <div class="col-md-12 pr-0 pl-0 mb-3">
                        <div class="card-body table-responsive table-head pb-0 mb-0">
                            <div class="row bor-bot">
                                <div class="col-md-8 pl-0">
                                    <h1 class="m-0 text-dark pt-2 pb-3 text-left">Campaign History</h1>
                                </div>
                                <div class="col-md-4 pt-1 pr-0">
                                    <div class="text-right pb-3">
                                      <ol class="breadcrumb float-sm-right pr-0">
                                        <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                        <li class="breadcrumb-item"><Breadcrumbs/></li>
                                      </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="camhistry">
                    <div class="camhistryfilter">
                      <form ref="form" method="post" @submit.prevent="filterOption()">
                        <div class="col-md-12 py-2 bor-bot mb-4">
                            <p class="mb-0">Filter By</p>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                    <label>From Date</label>
                                    </div>
                                    <div class="col-md-9 datepicsec">
                                        <div class="input-group birthdate">
                                            <div class="birthdaypicker">
                                                <date-pick
                                                    id="filterStartDate"
                                                    v-model="filter.startDate"
                                                    :format="'MM/DD/YYYY'"
                                                    :displayFormat="'MM/DD/YYYY'"
                                                    v-mask="'##/##/####'"
                                                    :parseDate="parseDatePick"
                                                ></date-pick>
                                            </div>
                                        </div>
                                      <span class="text-sm text-danger" style="display: block;" v-if="errors.startDate">{{
                                          errors.startDate
                                        }}</span>
                                    </div> 
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3 p-0">
                                    <label>To Date</label>
                                    </div>
                                    <div class="col-md-9 datepicsec">
                                        <div class="input-group birthdate">
                                            <div class="birthdaypicker">
                                                <date-pick
                                                    v-model="filter.endDate"
                                                    :format="'MM/DD/YYYY'"
                                                    :displayFormat="'MM/DD/YYYY'"
                                                    v-mask="'##/##/####'"
                                                    id="endDate"
                                                    :parseDate="parseDatePick"
                                                ></date-pick>
                                            </div>
                                        </div>
                                      <span class="text-sm text-danger" style="display: block;" v-if="errors.endDate">{{
                                          errors.endDate
                                        }}</span>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-3">
                                <label>Membership Type</label>
                              </div>
                              <div class="col-md-9">
                                <select  id="membershipType" v-model="filter.membershipType" class="form-control">
                                  <option disabled value="">Select</option>
                                  <option v-for="(id, val) in membershipData" :value="val" :key="val">{{ id }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-3 p-0">
                                <label>Email Template Type</label>
                              </div>
                              <div class="col-md-9">
                                <select id="templateType" v-model="filter.template_type_id" class="form-control">
                                  <option disabled value="">Select</option>
                                  <option v-for="(option,r) in templateType" :key="r" :value="option.id">
                                    {{ option.type }}
                                  </option>
                                </select>
                                <span class="text-sm text-danger" style="display: block;" v-if="errors.template_type_id">{{
                                    errors.template_type_id
                                  }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 mb-4 pr-3">
                            <div class="text-right">
                                <button type="button" @click="clearFilter" class="btn btn-secondary mr-3">Clear</button>
                                <button type="submit" class="btn darkblubtn btn-outline-primary">Apply</button>
                            </div> 
                        </div>
                      </form>
                    </div>

                <div class="row my-3">
                    <div class="col-md-12 pr-0">
                        <div class="text-right pr-0 emltmpserchsec">
                            <div class=" card-text d-flex align-items-center float-right dwnlodreportsec"> 
                                <p class="mb-0 mr-3">Download Report</p>
                                <div>
                                  <b-button @click="excelExport()" :disabled="excelExportProgress"><i class="fas" :class="[ excelExportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i><img src="/svg/waterski/xldocument.svg"/></b-button>
                                  <b-button @click="pdfExport()" :disabled="pdfExportProgress"><i class="fas" :class="[ pdfExportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i><img src="/svg/waterski/pdfdownloade.svg"/></b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <table class="table table-hover" id="CampaignList">
                    <thead>
                        <tr>
                            <th>Template Type</th>
                            <th>Template Name</th>
                            <th>Status</th>
                            <th>Sent Date & Time</th>
                            <th>Schedule Date & Time</th>
                            <th class="d-none">Created</th>
                            <th>Actions</th>

                        </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </table>

                </div>
                </div>
            </div>
</section>
</template>

<script>
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import CustomAlert from "../CustomAlert";
export default {
  components:{
    CustomAlert
  },

  data() {
    return {
      filter: {
        template_type_id: null,
        membershipType: null,
        startDate: null,
        endDate: null,
      },
      templateType: [],
      emailTemplate: [],
      errors: {
        startDate: null,
        endDate: null,
        membershipType: null,
        template_type_id: null,
      },
      table: {},
      membershipData:[],
      excelExportProgress:false,
      pdfExportProgress:false,
      resendDisable:true
    };
  },
  methods: {
    templateTypeList() {
      axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/templateType/getTemplateType',
          {}, {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {

        if (response.data.status == 'Success') {
          this.templateType = response.data.templateType;
          console.log(this.templateType);
        } else {
          console.log(response);
        }
      }).catch(error => {
        this.errors = error.response.data.errors || {};

      });
    },
    templateTypeChange(event) {
      axios.get(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/getEmailTemplateWithType/' + event.target.value,
          {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {

        if (response.data.status == 'Success') {
          this.emailTemplate = response.data.emailTemplate;
          console.log(this.emailTemplate);
        } else {
          console.log(response);
        }

      }).catch(error => {
        this.errors = error.response.data.errors || {};

      });
    },
    filterOption() {
      if (!this.validate()) {
        return;
      }
      console.info(this.table);
      this.table.ajax.reload();
    },
    validate() {
      this.errors.endDate = null;
      if (this.filter.endDate != null && this.filter.startDate != null) {
        if (moment(this.filter.endDate).isBefore(this.filter.startDate)) {
          this.errors.endDate = 'End date must be greater than Start date!';
          return false;
        }
        return true;
      }
      return true;
    },
    campaignTable(){
      $.ajaxSetup({
        headers: {
          'application-id': process.env.VUE_APP_APPLICATION_ID,
          'ClientId': this.sassClientIds[this.subdomain]
        }
      });
      var self = this;
      this.table = $("#CampaignList").DataTable({
        sDom: "lfrtip",
        processing: true,
        serverSide: true,
        pageLength: 10,
        "bFilter": true,
        ajax: {
          url: process.env.VUE_APP_EMAIL_CAMPAIGN_URL + "api/campaignList/getCampaignList",
          type: 'post',
          data: function (d) {
            d.templateTypeId = self.filter.template_type_id;
            d.membershipType = self.filter.membershipType;
            d.startDate = self.filter.startDate;
            d.endDate = self.filter.endDate;
          }
        },
        columns: [
          {
            data: 'template_type', name: 'templateType.type', render: (data) => {
              return data || "N/A";
            }
          },
          {
            data: "email_template", name: "emailTemplate.name", render: (data) => {
              return data || "N/A";
            }
          },
          {
            data: "status", orderable: false, name: "status", render: (data) => {
              return data || "N/A";
            }
          },
          {
            data: "updated_at", orderable: false, name: "updated_at", render: (data) => {
              return data || "N/A";
            }
          },
          {
            data: "startDate", name: "start_date", render: (data) => {
              return data || "N/A";
            }
          },
          {
          data: "created_at", name: "created_at", visible:false, render: (data) => {
        return data || "N/A";
      }
      },

        {
            data: null, orderable: false, searchable: false,render: (data) => {
              let btn = '';
              btn += `<span class="primary-text repeat"  data-status='${self.resendDisable}' title="Resend Email" data-id='${data.id}' ><img src="/svg/waterski/circlemsg.svg"/></span> <span class="copied d-none" title="Copy" data-id='${data.id}' ><img src="/svg/waterski/copymessage.svg"/></span>`
              return btn
            }
          },
        ],
        order: [[5, "desc"]]
      });
      $(document).on("click", "table#CampaignList tbody .repeat" , function(e){
        e.preventDefault();
        if($(this).data('status')==true) {
          self.resendDisable = false;
          var id = ($(this).data("id") ? $(this).data('id') :
              ($(this).data() != null && $(this).data() != undefined && $(this).data("id") ? $(this).data('id') : null));
          if (($(this).data() != null && $(this).data() != undefined)) {
            self.campaignRepeat(id);
            return false;
          }
        }
        }).on("click", "table#CampaignList tbody .copied" , function(e){

        });

    },
    getMembership() {
      axios.get(this.basePath + "api/getMemberships")
          .then((response) => {
            this.membershipData = response.data.data;
          })
          .catch(error => {
            console.log("error getting Membership info")
          })
    },
    campaignRepeat(id){
      axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/campaign/repeat/' + id,{},
          {
            headers: {
              "application-id": process.env.VUE_APP_APPLICATION_ID,
              'Content-Type': 'application/json'
            }
          }).then(response => {
        if (response.data.status == 'Repeat Success') {
          this.table.ajax.reload();
          this.alertMessage = "Campaign Resent Successfully";
          this.displayAlert = true;
          this.resendDisable = true;
        } else {
          console.log(response);
        }

      }).catch(error => {
        this.errors = error.response.data.errors || {};
        this.resendDisable = true;
      });
    },
    campaignCopied(id){
    },
    excelExport(){
      var self = this;
      $.ajax({
        url: process.env.VUE_APP_EMAIL_CAMPAIGN_URL + "api/campaign/excelExport",
        type: 'post',
        "data": this.table.ajax.params(),
        beforeSend: function(){
          self.excelExportProgress = true;
        },
        "success": function(res) {
          self.excelExportProgress = false;
          if(res.file_url != undefined) window.open(res.file_url, '_blank');
        },
        error: function(err){
          console.log(err);
          self.excelExportProgress = true;
        }
      });
    },
    pdfExport(){
      var self = this;
      $.ajax({
        url: process.env.VUE_APP_EMAIL_CAMPAIGN_URL + "api/campaign/PDFExport",
        type: 'post',
        "data": this.table.ajax.params(),
        beforeSend: function(){
          self.pdfExportProgress = true;
        },
        "success": function(res) {
          self.pdfExportProgress = false;
          if(res.file_url != undefined) window.open(res.file_url, '_blank');
        },
        error: function(err){
          console.log(err);
          self.pdfExportProgress = false;
        }
      });
    },
    clearFilter(){
      this.filter.template_type_id = null;
      this.filter.membershipType = null;
      this.filter.startDate = null;
      this.filter.endDate = null;
      this.errors.endDate = null;
      this.table.ajax.reload();
    }

  },
  mounted() {
    this.templateTypeList();
    this.campaignTable();
    this.getMembership();
    var self = this;
  },
}
</script>

